import './continue-shopping-dropdown-links'

$(() => {

  // ** change password text to add hint
  $('.tn-password').after('<span style="margin: 0.5em 0; display: block; font-size: 85%">Passwords must contain at least 6 characters including at least one capital letter, number and special character</span>');

 $('.tn-flex-package-detail-page').prepend('<a class="c-back-link" href="/packages"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to subscription selection</a>');

 $('.tn-account-create-page').prepend('<a class="c-back-link" href="/account/login"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to login</a>');


})