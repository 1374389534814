const continueShoppingButton = document.querySelector('.tn-cart-buttons__item-container .tn-cart-buttons__secondary-action')
const dropDownLinkList = `<div class="c-continue-shopping"><label for="continue-shopping">Continue shopping for</label><br/>
                    <select class="form-control c-continue-shopping__select" name="continue-shopping">
                    <option value="" disabled selected>Choose</option>
                    <option value="https://my.theatreworks.org/events">Single Tickets</option>
                    <option value="https://theatreworks.org/subscribe">Subscriptions</option>
                    <option value="https://theatreworks.org/support/individual-giving/">Contributions</option>
                    </select></div>`

if (continueShoppingButton && continueShoppingButton.innerText === 'Continue Shopping') {
  continueShoppingButton.parentElement.innerHTML = dropDownLinkList
  document.querySelector('[name=continue-shopping]').addEventListener('change', (e) => {
    window.location = e.target.value
  })
}
